<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <MyRight />
    <div>
      <div class="img-box">
        <img src="./../static/img-box.png" alt="">
        <div class="img-text">
          <div class="img-text-title1">保障薪权益</div>
          <div class="img-text-title2">为农民工工资保驾护航！</div>
        </div>
      </div>
      <div style="display: flex;justify-content: center;">
        <div class="content" v-html="myContent.articleContent"></div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import MyRight from "@/components/MyRight.vue";
import { detail } from "@/api/index.js"
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
let router = useRouter()
let myContent = ref({});
let id = ref();
let getContent = async () => {
  await detail({ id: id.value }).then(res => {
    myContent.value = res.data
    console.log(res);
  })
}
onMounted(async () => {
  id.value = router.currentRoute.value.query.id
  await getContent()
})
</script>
<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.img-box {
  width: 100%;
  height: 380px;
  position: relative;
}
.img-text {
  width: 1300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.img-text-title1 {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 35.16px;
  color: rgba(51, 51, 51, 1);
  margin: 130px 0 0 100px;
}
.img-text-title2 {
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 49.22px;
  color: rgba(0, 82, 217, 1);
  margin: 10px 0 0 100px;
}
.content {
  min-height: 100px;
  width: 1300px;
  margin-top: 30px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 52px;
  box-sizing: border-box;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
